import React, { useState, useMemo } from 'react';
import './LoadsS2.css';
import { IoLogoAndroid } from 'react-icons/io';
import { FaApple } from 'react-icons/fa';
import Modal from '../Modal/index';

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
}

const images = importAll(
  require.context('./assets', false, /\.(png|jpe?g|svg)$/)
);

export default function ItemsLoading({ setOpenModal }) {
  const apps = [
    {
      name: 'FreeFire+',
      rating: '4.9',
      imageUrl: images['freefire.png'],
      description:
        'Discover the best way to get unlimited resources in this great game with an incredible MOD.',
      downloads: '1m',
    },
    {
      name: 'COD: Warzone Mobile+',
      rating: '4.9',
      imageUrl: images['wz.png'],
      description:
        'Discover the best way to get unlimited resources in this great game with an incredible MOD.',
      downloads: '1m',
    },
      {
      name: 'Dragon Ball : Legends+',
      rating: '4.9',
      imageUrl: images['dbz.png'],
      description:
        'Discover the best way to get unlimited resources in this great game with an incredible MOD.',
      downloads: '1m',
    },
    {
      name: 'Parchis STAR+',
      rating: '4.7',
      imageUrl: images['parchis.png'],
      description:
        'Discover the best way to get unlimited resources in this great game with an incredible MOD.',
      downloads: '100m',
    },
    {
      name: 'Asphalt Legends Unite+',
      rating: '4.7',
      imageUrl: images['au.png'],
      description:
        'Discover the best way to get unlimited resources in this great game with an incredible MOD.',
      downloads: '100m',
    },
    {
      name: '9 Baseball+',
      rating: '4.9',
      imageUrl: images['9bs.png'],
      description:
        'Unlock gems and infinite coins in this wonderful game Football Strike with our modification, works for both android and ios.',
      downloads: '1M+',
    },
    {
      name: 'Football Strike+',
      rating: '4.9',
      imageUrl: images['fs.png'],
      description:
        'Unlock Unlimited Free Spins in this wonderful game Coin Master with our modification, works for both android and ios.',
      downloads: '1M+',
    },
    {
      name: 'MONOPOLY GO+',
      rating: '4.8',
      imageUrl: images['mono.png'],
      description:
        'Discover the best way to get unlimited resources in this great game with an incredible MOD.',
      downloads: '1M+',
    },
    {
      name: 'DLS 25+',
      rating: '4.9',
      imageUrl: images['BKnnB8S8F5image_2023-12-07_181311674.png'],
      description:
        'Newest DLS 24 patch has been fixed - We implemented newest update in our DLS 24 ++ APP! Get it now for iOS & Android device and test it out!',
      downloads: '643K+',
    },
    {
      name: 'TikTok Coins+',
      rating: '4.9',
      imageUrl: images['kadhSAUUQBimage_2023-08-10_092204127.png'],
      description:
        'Current only-working and patched TikTok+ app on the market. Tested and fixed for both platforms - iOS & Android.',
      downloads: '2M+',
    },
    {
      name: 'TikTok Followers+',
      rating: '4.8',
      imageUrl: images['kadhSAUUQBimage_2023-08-10_092204127.png'],
      description:
        'Current only-working and patched TikTok+ app on the market. Tested and fixed for both platforms - iOS & Android.',
      downloads: '3M+',
    },
    {
      name: 'Spotify Accounts+',
      rating: '4.7',
      imageUrl: images['sp.png'],
      description:
        'Current only-working and patched Spotify Accounts+ app on the market. Tested and fixed for both platforms - iOS & Android.',
      downloads: '3M+',
    },
    {
      name: 'Paramount Accounts+',
      rating: '4.9',
      imageUrl: images['pp.png'],
      description:
        'Current only-working and patched Paramount Accounts+ app on the market. Tested and fixed for both platforms - iOS & Android.',
      downloads: '3M+',
    },
    {
      name: 'Crunchyroll+',
      rating: '4.5',
      imageUrl: images['cr.png'],
      description:
        'Current only-working and patched Crunchyroll+ app on the market. Tested and fixed for both platforms - iOS & Android.',
      downloads: '3M+',
    },
    {
      name: 'Gta 6+',
      rating: '4.5',
      imageUrl: images['gta6.png'],
      description:
        'Current only-working and patched Gta6+ app on the market. Tested and fixed for both platforms - iOS & Android.',
      downloads: '3M+',
    },
    {
      name: 'Max Accounts+',
      rating: '4.8',
      imageUrl: images['max.png'],
      description:
        'Current only-working and patched Max Accounts+ app on the market. Tested and fixed for both platforms - iOS & Android.',
      downloads: '3M+',
    },
    {
      name: 'Disney Accounts+',
      rating: '5',
      imageUrl: images['dn.png'],
      description:
        'Current only-working and patched Disney+ app on the market. Tested and fixed for both platforms - iOS & Android.',
      downloads: '3M+',
    },
    {
      name: 'Netflix Accounts+',
      rating: '4.8',
      imageUrl: images['nf.png'],
      description:
        'Current only-working and patched Netflix Accounts+ app on the market. Tested and fixed for both platforms - iOS & Android.',
      downloads: '3M+',
    },
    {
      name: 'Coin Master+',
      rating: '4.8',
      imageUrl: images['cm.png'],
      description:
        'Get Unlimited Spins for Coin Master by using our modified app, which works perfectly on both iOS and Android devices.',
      downloads: '423k+',
    },
    {
      name: 'Dragon City+',
      rating: '4.8',
      imageUrl: images['dc.png'],
      description:
        'Get endless gems for Dragon City by using our modified app, which works perfectly on both iOS and Android devices.',
      downloads: '423k+',
    },
    {
      name: 'Car Parking Multiplayer+',
      rating: '4.9',
      imageUrl: images['OWDsiJQ6D8image_2023-09-25_003602220.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices',
      downloads: '782K+',
    },
    {
      name: 'FC Mobile+',
      rating: '5.0',
      imageUrl: images['XMbOQhHY3kimage_2023-12-07_181345295.png'],
      description:
        'Access limitless in-game items with our unique app, designed for iOS and Android.',
      downloads: '3M+',
    },
    {
      name: 'COD Mobile+',
      rating: '4.9',
      imageUrl: images['cod.png'],
      description:
        'Get unlimited resources for COD mobile using our modded app that works for both iOS & Android.',
      downloads: '340k+',
    },
    {
      name: 'Monster Legends+',
      rating: '4.5',
      imageUrl: images['HuOQkE5aL6image_2023-09-25_004843834.png'],
      description:
        'Gain boundless game resources with our exclusive app, suitable for iOS and Android.',
      downloads: '900K+',
    },
    {
      name: 'Bingo Blitz+',
      rating: '5.0',
      imageUrl: images['XPHpeWsyPNimage_2023-08-31_181340740.png'],
      description:
        'Get unlimited resources for Bingo Blitz using our modded app that works for both iOS & Android devices.',
      downloads: '490K+',
    },
    {
      name: 'Roblox+',
      rating: '4.8',
      imageUrl: images['roblox.png'],
      description:
        'Secure unlimited game materials using our custom app, functional on iOS and Android.',
      downloads: '200K+',
    },
    {
      name: 'Royal Match+',
      rating: '5.0',
      imageUrl: images['twsYTAGsHDimage_2023-08-25_213421328.png'],
      description:
        'Get unlimited resources for Royal Match using our modded app that works for both iOS & Android devices.',
      downloads: '3M+',
    },
    {
      name: 'eFootball+',
      rating: '4.9',
      imageUrl: images['ef.png'],
      description:
        'Get unlimited GP coins in eFootball with our mod application compatible with iOS & Android.',
      downloads: '780K+',
    },
    {
      name: 'NFS No Limits+',
      rating: '4.8',
      imageUrl: images['nfs.png'],
      description:
        'Obtain endless game assets through our one-of-a-kind app, operational on iOS and Android.',
      downloads: '643K+',
    },
    {
      name: '8 Ball Pool+',
      rating: '4.8',
      imageUrl: images['8ball.png'],
      description:
        'Get unlimited gems for 8 Ball Pool using our modded app that works for both iOS & Android devices.',
      downloads: '893K+',
    },
    {
      name: 'Real Racing 3+',
      rating: '4.9',
      imageUrl: images['R3WTrs1bqrimage_2023-09-25_005150356.png'],
      description:
        'Enjoy limitless game provisions with our tailored app, functioning on both iOS and Android.',
      downloads: '2M+',
    },
    {
      name: 'SimCity BuildIt+',
      rating: '4.7',
      imageUrl: images['JSLd7DoEqvimage_2023-09-25_005349358.png'],
      description:
        'Attain boundless game supplies using our specialized app, suitable for iOS and Android.',
      downloads: '3M+',
    },
    {
      name: 'Top Eleven+',
      rating: '4.7',
      imageUrl: images['FBPQxaE8ifimage_2023-10-01_203104976.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '233K+',
    },
     {
      name: 'CarX Street+',
      rating: '4.4',
      imageUrl: images['carx.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '33M+',
    },
    {
      name: 'CarX Rally+',
      rating: '4.4',
      imageUrl: images['car2.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '33M+',
    },
    {
      name: 'CarX Drift Racing 2+',
      rating: '4.4',
      imageUrl: images['car3.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '33M+',
    },
    {
      name: 'CarX Drift Racing 3+',
      rating: '4.4',
      imageUrl: images['car4.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '33M+',
    },
    {
      name: 'Car Parking Multiplayer 2+',
      rating: '4.4',
      imageUrl: images['c2.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '33M+',
    },
    {
      name: 'Subway Surfers+',
      rating: '4.6',
      imageUrl: images['ss.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '18M+',
    },
     {
      name: 'Traffic Rider+',
      rating: '4.4',
      imageUrl: images['tf.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '20M+',
    },
    {
      name: 'Soccer Super Star+',
      rating: '4.8',
      imageUrl: images['st.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '5M+',
    },
    {
      name: 'Pubg Mobile+',
      rating: '4.0',
      imageUrl: images['pg.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '50M+',
    },
    {
      name: 'TownShip+',
      rating: '4.9',
      imageUrl: images['6Q8fLRYMOximage_2023-08-25_213400119.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '50M+',
    },
    {
      name: 'Candy Crush Soda+',
      rating: '4.3',
      imageUrl: images['cs.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '70M+',
    },
    {
      name: 'BloodStrike+',
      rating: '4.6',
      imageUrl: images['bs.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '8M+',
    },
    {
      name: 'Candy Crush Saga+',
      rating: '4.1',
      imageUrl: images['candysaga.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '10M+',
    },
    {
      name: 'Stumble Guys+',
      rating: '4.1',
      imageUrl: images['sg.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '20M+',
    },
    {
      name: 'Football League+',
      rating: '4.9',
      imageUrl: images['fl.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '150K+',
    },
    {
      name: 'Pokemon Go+',
      rating: '4.4',
      imageUrl: images['pgo.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '50M+',
    },
    {
      name: 'One Piece Bounty Rush+',
      rating: '4.4',
      imageUrl: images['RvfM2bhPuUimage_2023-10-01_205736085.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '12M+',
    },
    {
      name: 'Basketball Stars+',
      rating: '4.8',
      imageUrl: images['bstars.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '17M+',
    },
    {
      name: 'GTA San Andreas+',
      rating: '4.8',
      imageUrl: images['sa.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '100M+',
    },
    {
      name: 'Nba Infinite+',
      rating: '4.9',
      imageUrl: images['nba.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '35M+',
    },
    {
      name: 'Nba Live+',
      rating: '4.5',
      imageUrl: images['tVnrIBr5O0image_2023-08-10_092404327.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '2M+',
    },
    {
      name: 'Shadow Fight 2+',
      rating: '4.5',
      imageUrl: images['sf.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '9M+',
    },
    {
      name: 'Angry Birds 2+',
      rating: '4.9',
      imageUrl: images['angry.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '15M+',
    },
    {
      name: 'Farlight 84+',
      rating: '4.0',
      imageUrl: images['far.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '1M+',
    },
    {
      name: 'Soccer Hero+',
      rating: '4.5',
      imageUrl: images['sh.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '88M+',
    },
    {
      name: 'Ultimate Draft Soccer+',
      rating: '4.5',
      imageUrl: images['uds.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '100K+',
    },
    {
      name: 'Head Soccer+',
      rating: '3.5',
      imageUrl: images['headsoccer.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '500K+',
    },
    {
      name: 'Score Hero+',
      rating: '5.0',
      imageUrl: images['scorehero.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '900K+',
    },
    {
      name: 'Mortal Kombat+',
      rating: '5.0',
      imageUrl: images['kombat.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '70M+',
    },
    {
      name: 'Pokemon Unite+',
      rating: '3.0',
      imageUrl: images['pokemon.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '10M+',
    },
    {
      name: 'CSR 2+',
      rating: '5.0',
      imageUrl: images['csr2.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '5M+',
    },
    {
      name: 'NBA 2K24+',
      rating: '4.6',
      imageUrl: images['nba2k.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '17M+',
    },
    {
      name: 'MLB Perfect Inning 24+',
      rating: '4.6',
      imageUrl: images['mlb.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '21M+',
    },
    {
      name: 'Golf Battle+',
      rating: '4.6',
      imageUrl: images['golf.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '1M+',
    },
    {
      name: 'Mario Kart Tour+',
      rating: '4.2',
      imageUrl: images['mariokart.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '33M+',
    },
    {
      name: 'F1 Clash+',
      rating: '4.7',
      imageUrl: images['f1.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '47M+',
    },
    {
      name: 'Yu Gi Oh! Master Duel+',
      rating: '4.7',
      imageUrl: images['yugi.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '7M+',
    },
    {
      name: 'UNO+',
      rating: '2.9',
      imageUrl: images['1.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '77M+',
    },
    {
      name: 'OSM 24 Football Manager Game+',
      rating: '3.5',
      imageUrl: images['osm24.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '9M+',
    },
    {
      name: 'SimCity Buildlt+',
      rating: '5',
      imageUrl: images['simcity.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '48M+',
    },
    {
      name: 'Call Of Dragons+',
      rating: '5',
      imageUrl: images['callof.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '8M+',
    },
    {
      name: 'Dice Dreams+',
      rating: '3.9',
      imageUrl: images['dice.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '3M+',
    },
    {
      name: 'Lord Mobile Godzilla Kong War+',
      rating: '4.5',
      imageUrl: images['lordm.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '90M+',
    },
    {
      name: 'Rise Of KingDoms Lost Crusade+',
      rating: '4.3',
      imageUrl: images['rise.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '4M+',
    },
    {
      name: 'Without Survival+',
      rating: '4.8',
      imageUrl: images['survival.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '9M+',
    },
    {
      name: 'Top Troops+',
      rating: '4.8',
      imageUrl: images['top.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '3M+',
    },
    {
      name: 'Hill Climb Racing 2+',
      rating: '4.2',
      imageUrl: images['hill.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '200K+',
    },
    {
      name: 'Drive Zone Online+',
      rating: '4.8',
      imageUrl: images['drive.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '3M+',
    },
    {
      name: 'Real Racing 3+',
      rating: '4.8',
      imageUrl: images['real.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '20M+',
    },
    {
      name: 'Hill Climb Racing+',
      rating: '4.0',
      imageUrl: images['hillone.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '800K+',
    },
    {
      name: 'Hungry Shark Evolution+',
      rating: '4.4',
      imageUrl: images['shark.png'],
      description:
        'Unlock endless game supplies with our special app, compatible with both iOS and Android devices.',
      downloads: '400K+',
    },
  ];

  const [searchQuery, setSearchQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  // const [selectedAppIndex, setSelectedAppIndex] = useState(null);
  const [information, setInformation] = useState(null);

  const openModal = (app) => {
    setInformation(app);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setInformation(null);
  };

  const filteredApps = useMemo(() => {
    if (!searchQuery) return apps;

    return apps.filter((app) =>
      app.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [searchQuery, apps]);

  return (
    <div className="all-apps-title-container">
      <div className="section-title-wrapper">
        <h3>All Apps</h3>
        <div className="qsearch-input">
          <svg class="qsearch-icon" viewBox="0 0 24 24">
            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
          </svg>
          <input
            type="text"
            placeholder="Search for apps..."
            className="search-input"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>

      <div className="all-apps-content">
        {filteredApps.map((app, index) => (
          <div className="app-card" key={index} onClick={() => openModal(app)}>
            <div className="app-card-content">
              <img
                src={app.imageUrl}
                alt={app.name}
                className="app-image"
                style={{ width: '83px', borderRadius: 12 }}
              />
              <div>
                <div className="app-header">
                  <p className="app-author">Author: Just App</p>
                  <div className="app-platforms">
                    <IoLogoAndroid className="app-icon" fill="#a6b0cf" />
                    <FaApple className="app-icon" fill="#a6b0cf" />
                  </div>
                </div>
                <div className="app-content">
                  <div className="app-name">{app.name}</div>
                  <div className="app-ubication">
                    <h3 className="app-rating">☆</h3>
                    <div className="app-rating-count">{app.rating}</div>
                  </div>
                </div>
                <div className="app-description">{app.description}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        app={information}
        setOpenModal={setOpenModal}
      />
    </div>
  );
}
